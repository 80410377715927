import { useState, useEffect } from 'react';
import { penly } from '../api';

const useDebouncedPenlyUserSearch = (organisationId, defaultValue) => {
    const [email, setEmail] = useState(defaultValue ?? '');
    const [isLoading, setLoading] = useState(true);
    const [userExists, setUserExists] = useState(false);

    useEffect(() => {
        const searchUser = async () => {
            try {
                setLoading(true);
                // Make API call to check if user exists based on the email input
                await penly.getUser(organisationId, email);
                setUserExists(true);
                setLoading(false);
            } catch (error) {
                if(error.response.status === 404){
                    setUserExists(false);
                }
                if(error.response.status === 400){
                    setUserExists(true);
                }
                setLoading(false);
            }
        };
        let timer;
        const delay = 300; // Debounce delay in milliseconds

        if (email.trim() !== '') {
            timer = setTimeout(() => {
                searchUser();
            }, delay);
        }

        return () => clearTimeout(timer);
    }, [email]);
    const handleEmailChange = (value) => {
        setEmail(value);
    };

    const resetSearch = () => {
        setEmail(defaultValue ?? '');
        setLoading(false);
        setUserExists(false);
    };

    return { email, isLoading, userExists, handleEmailChange, resetSearch };
};

export default useDebouncedPenlyUserSearch;
